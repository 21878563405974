import _isEmpty from 'lodash/isEmpty';

export const state = () => ({
  choices: {
    interpretation_type: [],
    interpretation_topic: [],
    performance_type: [],
  },
  equipReports: [],
});

export const mutations = {
  SET_CHOICES(state, payload) {
    state.choices = payload;
  },
  SET_EQUIP_REPORTS(state, payload) {
    state.equipReports = payload;
  },
};

export const getters = {
  getEquipReports: (state) => ({ requestId }) => {
    const data = window.localStorage.getItem(`equipReport-${requestId}`);

    return data ? JSON.parse(data) : [];
  },
};

export const actions = {
  async fetchChoices({ commit, state }) {
    if (_isEmpty(state.choices.interpretation_type) || _isEmpty(state.choices.interpretation_topic)) {
      try {
        const choices = {
          interpretation_type: await useApi.get('/operator/requests/choices-equip?name=interpretation_type'),
          interpretation_topic: await useApi.get('/operator/requests/choices-equip?name=interpretation_topic'),
          performance_type: await useApi.get('/operator/requests/choices-equip?name=performance_type'),
        };

        commit('SET_CHOICES', choices);
      } catch (error) {
        console.error(error);
        ElMessage.error('Chyba při načítání hodnot z eQuipu. Zapište prosím požadavek do eQuipu ručně.');
      }
    }
  },

  async fetchEquipReports({ commit }, { requestId }) {
    try {
      const reports = await useApi.get(`/operator/requests/${requestId}/equip`);

      commit('SET_EQUIP_REPORTS', reports);
    } catch (error) {
      console.error(error);
      ElMessage.error('Chyba při načítání reportu z eQuipu. Zapište prosím požadavek do eQuipu ručně.');
    }
  },

  async getClientEquipReports(_, { clientId, params }) {
    try {
      const reports = await useApi.get(`/operator/users/${clientId}/equips`, { params });

      return reports;
    } catch (error) {
      console.error(error);
      ElMessage.error('Chyba při načítání reportu z eQuipu.');
    }
  },

  saveEquipReports(_, { requestId, data }) {
    console.log('saveEquipReports', requestId, data);
    window.localStorage.setItem(`equipReport-${requestId}`, JSON.stringify(data));
  },

  clearEquipReports(_, { requestId }) {
    window.localStorage.removeItem(`equipReport-${requestId}`);
  },
};
