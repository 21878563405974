export const state = () => ({
  requestTypes: [],
});

export const mutations = {
  REQUEST_TYPES_SET(state, payload) {
    state.requestTypes = payload;
  },
};

export const actions = {
  async getRequestTypes({ commit }) {
    try {
      const response = await useApi.get('client/requests/types');
      commit('REQUEST_TYPES_SET', response);
    } catch (error) {
      console.error(error);
    }
  },

};
