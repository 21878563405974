
export const state = () => ({
  total: 0,
  perPage: 25,
  users: [],
  user: {},
  page: {
    current: 1,
    hasPrevious: false,
    hasNext: false,
  },
});

export const mutations = {
  USERS_SET(state, payload) {
    state.users = payload;
  },
  USERS_TOTAL_SET(state, payload) {
    state.total = payload;
  },
  USER_SET(state, payload) {
    state.user = payload;
  },
  USER_UPDATE(state, { id, data }) {
    state.users = state.users.map((user) => {
      if (user.id === id) {
        user = { ...user, ...data };
      }

      return user;
    });

    if (state.user && state.user.id === id) {
      state.user = { ...state.user, ...data };
    }
  },
  PAGE_UPDATE(state, payload) {
    state.page = { ...state.page, ...payload };
  },
};

export const getters = {
  getUser: (state) => (id) => {
    return state.users.find((item) => item.id == id);
  },
};

export const actions = {
  async getUsers({ commit, state }, params) {
    const response = await useApi.get('/operator/users', {
      params: {
        limit: state.perPage,
        page: state.page.current,
        ...params,
      },
    });

    commit('USERS_SET', response.results);
    commit('USERS_TOTAL_SET', response.count);
  },

  async changePage({ commit, dispatch }, to) {
    commit('PAGE_UPDATE', { current: to });
    await dispatch('getUsers', {
      staff: 0,
    });
  },

  async getUser({ commit }, id) {
    const user = await useApi.get(`/operator/users/${id}`);

    commit('USER_SET', user);
  },

  async updateUser({ commit }, { id, data }) {
    await useApi.patch(`/operator/users/${id}`, {
      body: data,
    });

    commit('USER_UPDATE', { id, data });
  },
};
