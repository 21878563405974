export const state = () => ({
  EMERGENCY_TYPE_ID: 4,
  types: [
    {
      id: 4,
      name: 'SOS',
      icon: 'icon-emergency',
      color: 'danger',
      lang: 'cs',
    },
    {
      id: 1,
      name: 'Úřad/Lékař',
      icon: 'icon-hospital',
      color: 'secondary',
      lang: 'cs',
    },
    {
      id: 2,
      name: 'Důležité',
      icon: 'icon-warning',
      color: 'tertiary',
      lang: 'cs',
    },
    {
      id: 3,
      name: 'Ostatní',
      icon: 'icon-coffee',
      color: 'green',
      lang: 'cs',
    },
    {
      id: 5,
      name: 'Лікар',
      nameTooltip: 'Lékař',
      lang: 'ua',
    },
    {
      id: 6,
      name: 'Поліція',
      nameTooltip: 'Policie',
      lang: 'ua',
    },
    {
      id: 7,
      name: 'Робота',
      nameTooltip: 'Práce',
      lang: 'ua',
    },
    {
      id: 8,
      name: 'Банк',
      nameTooltip: 'Banka',
      lang: 'ua',
    },
    {
      id: 9,
      name: 'Уряд',
      nameTooltip: 'Úřad',
      lang: 'ua',
    },
    {
      id: 10,
      name: 'Школа',
      nameTooltip: 'Škola',
      lang: 'ua',
    },
    {
      id: 11,
      name: 'Сімейні справи',
      nameTooltip: 'Rodinné záležitosti',
      lang: 'ua',
    },
    {
      id: 12,
      name: 'Магазини',
      nameTooltip: 'Obchody',
      lang: 'ua',
    },
    {
      id: 13,
      name: 'Послуги (електрика, вода, телефон ...)',
      nameTooltip: 'Služby (elektřina, voda, telefon...)',
      lang: 'ua',
    },
    {
      id: 14,
      name: 'Житло',
      nameTooltip: 'Bydlení',
      lang: 'ua',
    },
    {
      id: 15,
      name: 'Інше',
      nameTooltip: 'Jiné',
      lang: 'ua',
    },
  ],
  states: [
    {
      id: 1,
      name: 'Nový',
    },
    {
      id: 2,
      name: 'Právě vyřizovaný',
    },
    {
      id: 3,
      name: 'Pozastavený',
    },
    {
      id: 4,
      name: 'Dokončený',
    },
    {
      id: 5,
      name: 'Zrušeno',
    },
  ],
  services: [
    {
      id: 1,
      name: 'Tlumočení',
      color: 'secondary',
      icon: 'icon-interpreting',
      iconXl: 'icon-interpreting-xl',
    },
    {
      id: 2,
      name: 'Přepis',
      color: 'tertiary',
      icon: 'icon-transcript',
      iconXl: 'icon-transcript-xl',
    },
    {
      id: 3,
      name: 'Relay tlumočení UZJ',
      color: 'primary',
      icon: 'icon-group',
      iconXl: 'icon-group-xl',
    },
  ],
});

export const mutations = {
  REQUEST_TYPES_SET(state, payload) {
    state.types = payload;
  },
  REQUEST_SERVICES_SET(state, payload) {
    state.services = payload;
  },
  REQUEST_STATES_SET(state, payload) {
    state.states = payload;
  },
};

export const actions = {
  async getRequestTypes({ commit }) {
    try {
      const response = await useApi.get('client/requests/types');
      commit('REQUEST_TYPES_SET', response.results);
    } catch (error) {
      console.error(error);
    }
  },
  async getRequestStates({ commit }) {
    try {
      const response = await useApi.get('client/requests/states');
      commit('REQUEST_STATES_SET', response.results);
    } catch (error) {
      console.error(error);
    }
  },
  async cancelRequest({ commit }, requestId) {
    try {
      await useApi.delete(`client/requests/${requestId}`);
      commit('REQUEST_REMOVE', requestId);
      ElMessage.success('Požadavek byl úspěšně odstraněn.');
    } catch (error) {
      ElMessage.error('Požadavek se nepodařilo odstranit.');
      console.error(error);
    }
  },
};

export const getters = {
  getRequestService: (state) => (id) => {
    return state.services.find((item) => item.id == id);
  },
  getRequestsType: (state) => (id) => {
    return state.types.find((item) => item.id == id);
  },
  getRequestTypesExceptEmergency: (state) => {
    return state.types.filter((type) => type.id !== state.EMERGENCY_TYPE_ID);
  },
  getType: (state) => (id) => {
    return state.types.find((type) => type.id == id);
  },
  getTypes: (state) => (lang) => {
    return lang ? state.types.filter((type) => type.lang == lang) : state.types;
  },
};
