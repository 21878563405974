<template>
  <div :hidden="route.hash === '#windowed'">
    <header class="Header">
      <div class="HeaderLeft">
        <nuxt-link
          :event="useAuthStore().user && useAuthStore().user?.is_temp ? '' : 'click'"
          to="/"
          @click="useError() ? clearError() : null"
        >
          <logo-svg />
        </nuxt-link>
      </div>
      <div class="HeaderNavigation container">
        <slot name="navigation" />
      </div>
      <div
        class="HeaderRight"
      >
        <template v-if="useAuthStore().user && !useAuthStore().user?.is_temp">
          <slot name="right" />

          <el-dropdown
            trigger="click"
            class="HeaderRightUser"
          >
            <el-button
              link
              class="tw-text-white"
            >
              <UserAvatar
                :user="useAuthStore().user"
                class="tw-mr-3"
              />
              <span class="color-white HeaderRightUserName">
                {{ useAuthStore().user?.last_name ? `${useAuthStore().user?.first_name} ${useAuthStore().user?.last_name}` : (useAuthStore().user?.name ? useAuthStore().user?.name : useAuthStore().user?.email) }}
              </span>
              <icon-caret-down-rounded class="color-white HeaderRightUserArrow" />
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <template v-if="useAuthStore().user?.is_admin">
                  <template v-if="!isAdminPage">
                    <el-dropdown-item @click="router.push(localePath('/admin/shifts'))">
                      Přejít do administrace
                    </el-dropdown-item>
                  </template>
                  <template v-else>
                    <el-dropdown-item @click="router.push(localePath('/operator/requests'))">
                      Přejít na tlumočení
                    </el-dropdown-item>
                  </template>
                </template>
                <template v-if="useAuthStore().user?.is_operator">
                  <el-dropdown-item @click="$refs.bugReportDialog.open()">
                    Nahlásit chybu
                  </el-dropdown-item>
                </template>

                <el-dropdown-item
                  :divided="useAuthStore().user?.is_admin || useAuthStore().user?.is_operator"
                  @click="router.push(localePath(`${useAuthStore().user?.is_operator ? '/operator' : ''}/settings/${useAuthStore().user?.type === 2 ? 'organization' : 'personal-data'}`))"
                >
                  {{ $t('AppHeader.settings') }}
                </el-dropdown-item>
                <el-dropdown-item
                  data-cy="logout"
                  @click="useLogout()"
                >
                  {{ $t('AppHeader.logout') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <app-header-burger />
        </template>
        <template v-if="useAuthStore().user && useAuthStore().user?.is_temp">
          <nuxt-link
            :to="localePath('/')"
            class="color-white"
            data-cy="logout"
            @click="useLogout()"
          >
            {{ $t('AppHeader.logout') }}
          </nuxt-link>
        </template>
      </div>
    </header>
    <AppHeaderBurgerMenu
      v-if="useUIStore().burgerMenu"
    >
      <template v-if="useAuthStore().user?.is_admin">
        <nuxt-link
          v-if="!isAdminPage"
          :to="localePath('/admin/shifts')"
        >
          Přejít do administrace
        </nuxt-link>
        <nuxt-link
          v-else
          :to="localePath('/operator/requests')"
        >
          Přejít na tlumočení
        </nuxt-link>
      </template>
      <slot name="navigation" />
    </AppHeaderBurgerMenu>

    <BugReportDialog
      ref="bugReportDialog"
    />
  </div>
</template>

<script setup>
import LogoSvg from '~/assets/img/logo.svg';

// Refs
const router = useRouter();
const route = useRoute();
const localePath = useLocalePath();

// Computed
const isAdminPage = computed(() => !!useRoute().path?.match(/^(\/admin)/g));
</script>

<style scoped>
.Header {
  @apply tw-bg-primary-500;
  width: 100%;
  @apply tw-text-white;

  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1210;

  padding-left: 10px;
  padding-right: 10px;
  height: var(--header-height-small);

  @media (min-width: 400px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @screen md {
    height: var(--header-height);
    padding-left: 24px;
    padding-right: 20px;
  }

  &Left {
    svg {
      width: 100px;
      @screen lg {
        width: auto;
      }
    }

    a {
      @apply tw-text-white;
    }
  }

  &Navigation {
    display: none;
    flex: 1;
    margin-left: 32px;

    @screen lg {
      margin-left: 72px;
    }

    @screen md {
      display: flex;
    }

    a,
    :deep(a) {
      @apply tw-transition tw-duration-300;
      @apply tw-text-white;
      display: flex;
      align-items: center;
      text-decoration: none;
      @apply tw-font-semibold;
      position: relative;
      padding-left: 2px;
      padding-right: 2px;

      &:not(:last-of-type) {
        margin-right: 15px;
        @media (min-width: 400px) {
          margin-right: 45px;
        }
      }

      height: var(--header-height-small);

      @screen md {
        height: var(--header-height);
      }

      @media (max-width: 400px) {
        @apply tw-text-sm;
      }

      &::before {
        @apply tw-transition tw-duration-300;
        content: '';
        display: block;
        bottom: 0;
        width: 100%;
        height: 3px;
        position: absolute;
        @apply tw-bg-white;
      }

      &:not(.router-link-active) {
        opacity: .7;

        &::before {
          opacity: 0;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &Right {
    display: flex;
    align-items: center;

    &User {
      margin-left: 20px;
      display: none;

      @screen md {
        display: block;
      }

      &Arrow {
        display: block;
        width: 10px;
        height: 10px;
        margin-left: 0;
        margin-top: 2px;

        @screen sm {
          margin-left: 12px;
        }
      }
    }
  }
}
</style>
