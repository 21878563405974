
export const state = () => ({
  total: 0,
  perPage: 10,
  notes: [],
  page: {
    current: 1,
    hasPrevious: false,
    hasNext: false,
  },
});

export const mutations = {
  NOTES_SET(state, payload) {
    state.notes = payload;
  },
  NOTES_TOTAL_SET(state, payload) {
    state.total = payload;
  },
  NOTE_ADD(state, payload) {
    state.notes.push(payload);
  },
  NOTE_UPDATE(state, { id, data }) {
    state.notes = state.notes.map((item) => {
      if (item.id === id) {
        item = { ...item, ...data };
      }

      return item;
    });
  },
  NOTE_REMOVE(state, id) {
    state.notes = state.notes.filter((item) => item.id !== id);
  },
  PAGE_UPDATE(state, payload) {
    state.page = { ...state.page, ...payload };
  },
};

export const getters = {
};

export const actions = {
  async getNotes({ commit, state }, userId) {
    try {
      const response = await useApi.get('/operator/notes', {
        params: {
          limit: state.perPage,
          page: state.page.current,
          user_id: userId,
        },
      });

      commit('NOTES_SET', state.page.current === 1 ? response.results : [...state.notes, ...response.results]);
      commit('NOTES_TOTAL_SET', response.count);
      commit('PAGE_UPDATE', { hasPrevious: !!response.previous, hasNext: !!response.next });
    } catch (error) {
      ElMessage.error('Chyba při načítání poznámek.');
      console.error(error);
    }
  },

  async addNote({ commit }, payload) {
    const note = await useApi.post('/operator/notes', {
      body: payload,
    });

    commit('NOTE_ADD', note);
  },

  async updateNote({ commit }, { id, data }) {
    await useApi.put(`/operator/notes/${id}`, {
      body: data,
    });

    commit('NOTE_UPDATE', { id, data });
  },

  async removeNote({ commit }, id) {
    await useApi.delete(`/operator/notes/${id}`);

    commit('NOTE_REMOVE', id);
  },
};
