export default defineNuxtRouteMiddleware(async(to, from) => {
  if (import.meta.client) {
    let isLoggedIn = useAuthStore().isLoggedIn;

    if (window.localStorage.getItem('token') && !isLoggedIn) {
      await useAuthStore().fetchUser();
      isLoggedIn = useAuthStore().isLoggedIn;
    }

    if (useError().value) { // for error page
      return abortNavigation();
    }

    if (isLoggedIn) {
      const userHomeLink = useAuthStore().user?.is_operator ? '/operator/requests' : '/requests';

      if (to.meta.layout === 'admin' && !useAuthStore().user?.is_admin) {
        return navigateTo(userHomeLink);
      }

      if (to.meta.layout === 'operator' && !useAuthStore().user?.is_operator) {
        return navigateTo(userHomeLink);
      }

      if (to.meta.layout === 'client' && useAuthStore().user?.is_operator) {
        return navigateTo(userHomeLink);
      }
    } else if (to.meta.layout !== 'public') {
      return navigateTo('/login');
    }
  }
});
