export const state = () => ({
  services: [
    {
      id: 1,
      name: 'Tlumočení ČZJ',
      nameUA: 'Relay nTLU UZJ',
      color: 'secondary',
      icon: 'icon-interpreting',
      iconXl: 'icon-interpreting-xl',
    },
    {
      id: 2,
      name: 'Přepis ČJ',
      color: 'tertiary',
      icon: 'icon-transcript',
      iconXl: 'icon-transcript-xl',
    },
    {
      id: 3,
      name: 'Relay sTLU UZJ',
      color: 'primary',
      icon: 'icon-group',
      iconXl: 'icon-group-xl',
    },
  ],
});

export const getters = {
  getRequestService: (state) => (id) => {
    return state.services.find((item) => item.id == id);
  },
  getRequestServiceName: (state) => (id, lang) => {
    const service = state.services.find((item) => item.id === Number(id));
    return lang === 'ua' ? (service?.nameUA || service?.name) : service?.name;
  },
};
