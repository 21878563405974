
export const state = () => ({
  total: 0,
  perPage: 25,
  organizations: [],
  page: {
    current: 1,
  },
  filter: {
    search: '',
  },
});

export const mutations = {
  ORGANIZATIONS_SET(state, payload) {
    state.organizations = payload;
  },
  ORGANIZATIONS_TOTAL_SET(state, payload) {
    state.total = payload;
  },
  PAGE_UPDATE(state, payload) {
    state.page = { ...state.page, ...payload };
  },
  FILTER_UPDATE(state, payload) {
    state.filter = { ...state.filter, ...payload };
  },
};

export const getters = {
};

export const actions = {
  async getOrganizations({ commit, state }) {
    const response = await useApi.get('/operator/users', {
      params: {
        limit: state.perPage,
        page: state.page.current,
        staff: 0,
        type: 2,
        q: state.filter.search.length > 2 ? state.filter.search : undefined,
      },
    });

    commit('ORGANIZATIONS_SET', response.results);
    commit('ORGANIZATIONS_TOTAL_SET', response.count);
  },

  async changePage({ commit, dispatch }, to) {
    commit('PAGE_UPDATE', { current: to });
    await dispatch('getOrganizations');
  },
};
