import logRocket from 'logrocket';

const plugin = defineNuxtPlugin(() => { // replace by nuxt-logrocket after fix https://github.com/nuxt-modules/logrocket/issues/360
  const { $pinia } = useNuxtApp();
  const opts = useRuntimeConfig()?.public?.logRocket;

  if (!opts?.id || (!opts?.dev && !(process.env.NODE_ENV === 'production')))
    return;

  logRocket.init(opts?.id, opts?.config);

  if ($pinia && opts?.enablePinia) {
    $pinia.use(({ store }) => {
      store.$subscribe((m) => logRocket.log('mutation', m));
    });
  }

  return { provide: { logRocket } };
});

export default plugin;
