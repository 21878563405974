export const state = () => ({
  isPublisherConnected: false,
  isSubscriberConnected: false,
  isPublisherMuted: false,
  isSubscriberMuted: false,
  tokboxTokens: null,
  isPublishing: false,
  isJoining: false,
  startTime: null,
});

export const mutations = {
  SET(state, [field, payload]) {
    state[field] = payload;
  },
};
