export const state = () => ({
  filter: {
    search: '',
    fromDate: dayjs().add(-1, 'day').format(),
    toDate: dayjs().add(13, 'day').format(),
  },
  shifts: [],
  currentAndUpcomingShifts: [],
  perPage: 25,
  page: {
    total: 0,
    current: 1,
    hasPrevious: false,
    hasNext: false,
  },
});

export const mutations = {
  SHIFTS_SET(state, payload) {
    state.shifts = payload;
  },
  SHIFT_ADD(state, payload) {
    state.shifts.push(payload);
  },
  SHIFT_UPDATE(state, { id, data }) {
    state.shifts = state.shifts.map((user) => {
      if (user.id === id) {
        user = { ...user, ...data };
      }

      return user;
    });
  },
  SHIFT_REMOVE(state, id) {
    state.shifts = state.shifts.filter((shift) => shift.id !== id);
  },
  CURRENT_AND_UPCOMING_SHIFTS_SET(state, payload) {
    state.currentAndUpcomingShifts = payload;
  },
  SEARCH_QUERY_SET(state, payload) {
    state.searchQuery = payload;
  },
  FILTER_UPDATE(state, payload) {
    state.filter = { ...state.filter, ...payload };
  },
  PAGE_UPDATE(state, payload) {
    state.page = { ...state.page, ...payload };
  },
};

export const getters = {
  getShift: (state) => (id) => {
    return state.shifts.find((shift) => shift.id === id);
  },

  getDays: (state) => {
    const uniqueDays = [...new Set((state.shifts).map((shift) => shift.day))];

    return uniqueDays.sort((a, b) => dayjs(a).format('YYYYMMDD') - dayjs(b).format('YYYYMMDD'));
  },

  hasCurrentAndUpcomingShifts: (state) => (now) => {
    return state.currentAndUpcomingShifts.some((shift) => dayjs(now).isSameOrAfter(shift.start_date) && dayjs(now).isSameOrBefore(shift.end_date));
  },
};

export const actions = {
  async getShifts({ commit, state }) {
    try {
      const response = await useApi.get('/operator/shifts', {
        params: {
          limit: state.perPage * state.page.current,
          from_date: state.filter.fromDate ? dayjs(state.filter.fromDate).format('YYYYMMDD') : undefined,
          to_date: state.filter.toDate ? dayjs(state.filter.toDate).format('YYYYMMDD') : undefined,
          q: state.filter.search.length > 2 ? state.filter.search : undefined,
        },
      });

      response.results = response.results.map((result) => {
        result.day = dayjs(result.start_date).format('YYYY-MM-DD');

        return result;
      });

      commit('SHIFTS_SET', response.results);
      commit('PAGE_UPDATE', { hasPrevious: !!response.previous, hasNext: !!response.next, total: response.count });
    } catch (error) {
      ElMessage.error('Chyba při načítání služeb.');
      console.error(error);
    }
  },

  async loadMore({ commit, dispatch, state }) {
    commit('PAGE_UPDATE', { current: state.page.current + 1 });

    await dispatch('getShifts');
  },

  async addShift({ commit, dispatch }, payload) {
    const shift = await useApi.post('/operator/shifts', {
      body: payload,
    });

    commit('SHIFT_ADD', shift);
    dispatch('getCurrentShift');
  },

  async updateShift({ commit, dispatch }, { id, data }) {
    await useApi.patch(`/operator/shifts/${id}`, {
      body: data,
    });

    commit('SHIFT_UPDATE', { id, data });
    dispatch('getCurrentShift');
  },

  async removeShift({ commit, dispatch }, id) {
    await useApi.delete(`/operator/shifts/${id}`);

    commit('SHIFT_REMOVE', id);
    dispatch('getCurrentShift');
  },

  async getCurrentShift({ commit }) {
    const response = await useApi.get('/operator/shifts', {
      params: {
        limit: 10,
        from_date: dayjs().format('YYYYMMDD'),
        to_date: dayjs().add(1, 'day').format('YYYYMMDD'),
      },
    });

    if (response && response.results) {
      commit('CURRENT_AND_UPCOMING_SHIFTS_SET', response.results);
    }
  },

  async relayHasPair({ commit }) {
    const response = await useApi.get('/operator/shifts/relay-has-pair');

    return response;
  },
};
