<template>
  <el-button
    type="primary"
    circle
    class="tw-flex md:tw-hidden"
    @click="useUIStore().burgerMenu = true"
  >
    <icon-burger
      class="tw-w-auto"
    />
  </el-button>
</template>
