<template>
  <div
    v-if="props.user"
    class="UserAvatar"
    :class="[
      { 'is-online': props.user?.is_online },
      props.size ? `UserAvatar--${props.size}` : '',
    ]"
  >
    <img
      v-if="props.user?.avatar"
      :src="`https://ik.imagekit.io/flqfoq4qjo/tr:w-80,h-80/${props.user?.avatar}`"
      :alt="avatarAlt"
    >
    <span v-else>
      {{ initials }}
    </span>
  </div>
</template>

<script setup>
const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  size: {
    type: String,
    default: null,
  },
});

// Computed
const initials = computed(() => {
  if (props.user?.name) { return props.user?.name[0] }

  if (props.user?.full_name) {
    const split = props.user?.full_name
      .split(' ')
      .map((e) => e[0])
      .join('');

    return split;
  }

  let inits = '';
  if (props.user?.first_name) { inits += props.user?.first_name[0] }

  if (props.user?.last_name) { inits += props.user?.last_name[0] }

  return inits;
});

const avatarAlt = computed(() => {
  if (props.user?.name) { return props.user?.name }
  if (props.user?.full_name) { return props.user?.full_name }

  return initials.value;
});
</script>

<style scoped>
.UserAvatar {
  position: relative;
  height: 40px;
  width: 40px;
  flex-shrink: 0;

  img {
    border-radius: 50%;
    overflow: hidden;
    width: 100%;
    z-index: 11;

    @supports (object-fit: cover) {
      object-fit: cover;
      height: 100%;
    }
  }

  span {
    position: absolute;
    content: '';
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #f3f3f3;
    @apply tw-text-sm;
    @apply tw-text-muted;
    @apply tw-font-bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--small {
    width: 32px;
    height: 32px;

    span {
      font-size: 13px;
    }
  }

  &--mini {
    width: 24px;
    height: 24px;

    span {
      font-size: 13px;
    }
  }

  &.is-online {
    &::after {
      position: absolute;
      content: '';
      z-index: 99;
      bottom: -2px;
      right: -2px;
      height: 12px;
      width: 12px;
      border: 2px solid white;
      border-radius: 50%;
      background-color: theme('colors.success');
    }
  }
}
</style>
