import unorm from 'unorm';

export const state = () => ({
  contacts: [],
  page: {
    current: 1,
    hasPrevious: false,
    hasNext: false,
  },
  filter: {
    search: '',
  },
});

export const mutations = {
  CONTACTS_SET(state, payload) {
    state.contacts = payload;
  },
  CONTACTS_APPEND(state, payload) {
    state.contacts = [...state.contacts, ...payload];
  },
  CONTACT_ADD(state, payload) {
    state.contacts.push(payload);
  },
  CONTACT_UPDATE(state, { id, data }) {
    state.contacts = state.contacts.map((contact) => {
      if (contact.id === id) {
        contact = { ...contact, ...data };
      }

      return contact;
    });
  },
  PAGE_UPDATE(state, payload) {
    state.page = { ...state.page, ...payload };
  },
  CONTACT_REMOVE(state, id) {
    state.contacts = state.contacts.filter((contact) => contact.id !== id);
  },
  FILTER_UPDATE(state, payload) {
    state.filter = { ...state.filter, ...payload };
  },
};

export const actions = {
  async getContacts({ commit, state }) {
    try {
      const response = await useApi.get('/client/user/contacts', {
        params: {
          page: state.page.current,
          q: state.filter.search.length > 2 ? state.filter.search : undefined,
        },
      });

      if (state.page.current === 1) {
        commit('CONTACTS_SET', response.results);
      } else {
        commit('CONTACTS_APPEND', response.results);
      }
      commit('PAGE_UPDATE', { hasPrevious: !!response.previous, hasNext: !!response.next });
    } catch (error) {
      ElMessage.error('Chyba při načítání kontaktů.');
      console.error(error);
    }
  },

  async loadMoreContacts({ commit, dispatch, state }) {
    commit('PAGE_UPDATE', { current: state.page.current + 1 });

    await dispatch('getContacts');
  },

  async addContact({ commit }, payload) {
    const contact = await useApi.post('/client/user/contacts', {
      body: payload,
    });

    commit('CONTACT_ADD', contact);
  },

  async updateContact({ commit }, { id, data }) {
    await useApi.put(`/client/user/contacts/${id}`, {
      body: data,
    });

    commit('CONTACT_UPDATE', { id, data });
  },

  async removeContact({ commit }, id) {
    await useApi.delete(`/client/user/contacts/${id}`);

    commit('CONTACT_REMOVE', id);
  },
};

export const getters = {
  search: (state) => (query) => {
    const normalizedQuery = unorm.nfd(query);

    return state.contacts?.filter((item) => {
      return unorm.nfd(item.name).includes(normalizedQuery) || unorm.nfd(item.phone).includes(normalizedQuery);
    });
  },
};
