export const state = () => ({
  employees: [],
  employee: {},
  page: {
    current: 1,
    hasPrevious: false,
    hasNext: false,
  },
  filter: {
    search: '',
  },
});

export const mutations = {
  EMPLOYEES_SET(state, payload) {
    state.employees = payload;
  },
  EMPLOYEE_SET(state, payload) {
    state.employee = payload;
  },
  EMPLOYEE_ADD(state, payload) {
    state.employees.push(payload);
  },
  EMPLOYEE_UPDATE(state, { id, data }) {
    state.employees = state.employees.map((employee) => {
      if (employee.id === id) {
        employee = { ...employee, ...data };
      }

      return employee;
    });
  },
  EMPLOYEE_REMOVE(state, id) {
    state.employees = state.employees.filter((contact) => contact.id !== id);
  },
  PAGE_UPDATE(state, payload) {
    state.page = { ...state.page, ...payload };
  },
  FILTER_UPDATE(state, payload) {
    state.filter = { ...state.filter, ...payload };
  },
};

export const actions = {
  async getEmployees({ commit, state }) {
    try {
      const response = await useApi.get('/client/user/employees', {
        params: {
          page: state.page.current,
          limit: 2,
          q: state.filter.search.length > 2 ? state.filter.search : undefined,
        },
      });

      commit('EMPLOYEES_SET', state.page.current === 1 ? response.results : [...state.employees, ...response.results]);
      commit('PAGE_UPDATE', { hasPrevious: !!response.previous, hasNext: !!response.next });
    } catch (error) {
      ElMessage.error('Chyba při načítání kontaktních osob.');
      console.error(error);
    }
  },

  async loadMoreEmployees({ commit, dispatch, state }) {
    commit('PAGE_UPDATE', { current: state.page.current + 1 });

    await dispatch('getEmployees');
  },

  async getEmployee({ commit }, id) {
    const response = await useApi.get(`/client/user/employees/${id}`);

    commit('EMPLOYEE_SET', response);
  },

  async addEmployee({ commit }, payload) {
    const employee = await useApi.post('/client/user/employees', {
      body: payload,
    });

    commit('EMPLOYEE_ADD', employee);
  },

  async updateEmployee({ commit }, { id, data }) {
    await useApi.put(`/client/user/employees/${id}/`, {
      body: data,
    });

    commit('EMPLOYEE_UPDATE', { id, data });
  },

  async removeEmployee({ commit }, id) {
    await useApi.delete(`/client/user/employees/${id}/`);

    commit('EMPLOYEE_REMOVE', id);
  },
};

export const getters = {

};
