import {
  audioVuexStore,
  chatVuexStore,
  indexVuexStore,
  requestsVuexStore,
  shiftsVuexStore,
  websocketVuexStore,
  adminactivitiesVuexStore,
  adminoperatorsVuexStore,
  adminratingsVuexStore,
  adminshiftsVuexStore,
  adminusersVuexStore,
  clientcontactsVuexStore,
  clientemployeesVuexStore,
  clientrequestsVuexStore,
  operatorclientsVuexStore,
  operatorequipVuexStore,
  operatornotesVuexStore,
  operatoroperatorsVuexStore,
  operatororganizationsVuexStore,
  operatorrequestsVuexStore,
  operatorshiftsVuexStore,
  operatorusersVuexStore 
} from "#imports"
const VuexStore = {
  ...indexVuexStore,
  modules: {
      audio: {
        ...audioVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      chat: {
        ...chatVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      requests: {
        ...requestsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      shifts: {
        ...shiftsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      websocket: {
        ...websocketVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      admin: {
        
        namespaced: true,
        modules: {
      activities: {
        ...adminactivitiesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      operators: {
        ...adminoperatorsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      ratings: {
        ...adminratingsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      shifts: {
        ...adminshiftsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      users: {
        ...adminusersVuexStore,
        namespaced: true,
        modules: {},
      }
    },
      }
    ,
      client: {
        
        namespaced: true,
        modules: {
      contacts: {
        ...clientcontactsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      employees: {
        ...clientemployeesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      requests: {
        ...clientrequestsVuexStore,
        namespaced: true,
        modules: {},
      }
    },
      }
    ,
      operator: {
        
        namespaced: true,
        modules: {
      clients: {
        ...operatorclientsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      equip: {
        ...operatorequipVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      notes: {
        ...operatornotesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      operators: {
        ...operatoroperatorsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      organizations: {
        ...operatororganizationsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      requests: {
        ...operatorrequestsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      shifts: {
        ...operatorshiftsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      users: {
        ...operatorusersVuexStore,
        namespaced: true,
        modules: {},
      }
    },
      }
    }, 
  }
export default VuexStore