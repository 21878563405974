
export const state = () => ({
  perPage: 500,
  filter: {
    search: '',
  },
  operators: [],
});

export const mutations = {
  OPERATORS_SET(state, payload) {
    state.operators = payload;
  },
  OPERATOR_UPDATE(state, { id, data }) {
    state.operators = state.operators.map((user) => {
      if (user.id === id) {
        user = { ...user, ...data };
      }

      return user;
    });
  },
  SEARCH_QUERY_SET(state, payload) {
    state.searchQuery = payload;
  },
  FILTER_UPDATE(state, payload) {
    state.filter = { ...state.filter, ...payload };
  },
};

export const getters = {
  getOperator: (state) => (id) => {
    return state.operators.find((operator) => operator.id === id);
  },
};

export const actions = {
  async getOperators({ commit, state }) {
    const response = await useApi.get('/operator/users', {
      params: {
        limit: state.perPage,
        staff: 1,
        q: state.filter.search.length > 2 ? state.filter.search : undefined,
      },
    });

    commit('OPERATORS_SET', response.results);
  },
};
