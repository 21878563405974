const FILTER_BASE = {
  search: '',
  services: [],
  rating: [],
  created_at: null,
  date_from: null,
  date_to: null,
  user_type: null,
};

export const state = () => ({
  total: 0,
  perPage: 25,
  ratings: [],
  rating: [
    {
      id: 1,
      title: '1 hvězda',
    },
    {
      id: 3,
      title: '3 hvězdy',
    },
    {
      id: 5,
      title: '5 hvězd',
    },
  ],
  userTypes: [
    {
      id: 1,
      title: 'Klient',
    },
    {
      id: 2,
      title: 'Organizace',
    },
  ],
  page: {
    current: 1,
  },
  filter: FILTER_BASE,
});

export const mutations = {
  RATINGS_SET(state, payload) {
    state.ratings = payload;
  },
  RATINGS_TOTAL_SET(state, payload) {
    state.total = payload;
  },
  PAGE_UPDATE(state, payload) {
    state.page = { ...state.page, ...payload };
  },
  FILTER_UPDATE(state, payload) {
    state.filter = { ...state.filter, ...payload };
  },
  FILTER_RESET(state) {
    state.filter = FILTER_BASE;
  },
};

export const getters = {
};

export const actions = {
  async getRatings({ commit, state }) {
    const response = await useApi.get('/admin/ratings', {
      params: {
        limit: state.perPage,
        page: state.page.current,
        rating: state.filter.rating.length ? [...state.filter.rating].join(',') : undefined,
        service_ids: state.filter.services.length ? [...state.filter.services].join(',') : undefined,
        date_from: state.filter.date_from || undefined,
        date_to: state.filter.date_to || undefined,
        q: state.filter.search.length > 2 ? state.filter.search : undefined,
        user_type: state.filter.user_type || undefined,
      },
    });

    commit('RATINGS_SET', response.results);
    commit('RATINGS_TOTAL_SET', response.count);
  },

  async changePage({ commit, dispatch }, to) {
    commit('PAGE_UPDATE', { current: to });
    await dispatch('getRatings');
  },
};
