
export const state = () => ({
  total: 0,
  perPage: 25,
  clients: [],
  page: {
    current: 1,
  },
  filter: {
    search: '',
    lang: '',
  },
});

export const mutations = {
  CLIENTS_SET(state, payload) {
    state.clients = payload;
  },
  CLIENTS_TOTAL_SET(state, payload) {
    state.total = payload;
  },
  PAGE_UPDATE(state, payload) {
    state.page = { ...state.page, ...payload };
  },
  FILTER_UPDATE(state, payload) {
    state.filter = { ...state.filter, ...payload };
  },
};

export const getters = {
};

export const actions = {
  async getClients({ commit, state }) {
    const response = await useApi.get('/operator/users', {
      params: {
        limit: state.perPage,
        page: state.page.current,
        staff: 0,
        type: 1,
        lang: state.filter.lang || undefined,
        q: state.filter.search.length > 2 ? state.filter.search : undefined,
      },
    });

    commit('CLIENTS_SET', response.results);
    commit('CLIENTS_TOTAL_SET', response.count);
  },

  async changePage({ commit, dispatch }, to) {
    commit('PAGE_UPDATE', { current: to });
    await dispatch('getClients');
  },
};
