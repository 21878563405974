export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      user: null,
      token: null,
      refresh: null,
    };
  },

  actions: {
    setAuth(payload) {
      if (payload) {
        const token = `Bearer ${payload.access}`;
        this.token = token;
        this.refresh = payload.refresh;

        window.localStorage.setItem('token', token);
        window.localStorage.setItem('refresh', payload.refresh);
      } else {
        this.token = null;
        this.refresh = null;
        this.user = null;
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('refresh');
      }
    },

    async login({ email, password }) {
      const response = await useApi.post('/auth/login', {
        body: {
          email,
          password,
        },
      });

      if (response) {
        this.setAuth(response);

        return response;
      }

      return false;
    },

    logout() {
      this.setAuth(null);
    },

    async fetchUser() {
      const response = await useApi.get('/client/user/me').catch(() => false);

      if (response) {
        this.user = response;

        return this.user;
      }

      return false;
    },
  },

  getters: {
    isLoggedIn(state) {
      return !!state.user;
    },
    isUserOrganization(state) {
      return state.user?.type === 2;
    },
  },
});
