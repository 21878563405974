export const state = () => ({
  isConnected: false,
  message: '',
  wasClosed: false,
});

export const mutations = {
  SOCKET_ONOPEN(state) {
    state.isConnected = true;
  },
  SOCKET_ONCLOSE(state) {
    state.isConnected = false;
    state.wasClosed = true;
  },
  // default handler called for all methods
  SOCKET_ONMESSAGE(state, message) {
    state.message = message;
  },
};

export const actions = {
  updateUser({ commit }, payload) {
    const data = {
      id: payload.user_id,
      data: payload.user,
    };

    if (useAuthStore().user?.is_operator) {
      commit('operator/operators/OPERATOR_UPDATE', data, { root: true });
      commit('operator/requests/REQUEST_UPDATE_USER', data, { root: true });
    }
  },

  updateOperator({ commit }, payload) {
    const data = {
      id: payload.operator_id,
      data: {
        requests_in_progress: payload.requests_in_progress,
        requests_paused: payload.requests_paused,
      },
    };

    if (useAuthStore().user?.is_operator) {
      commit('operator/operators/OPERATOR_UPDATE', data, { root: true });
    }
  },

  addRequest({ dispatch }, payload) {
    if (useAuthStore().user?.is_operator) {
      dispatch('operator/requests/addRequest', payload.request, { root: true });
      dispatch('operator/requests/getPendingRequests', {}, { root: true });
    }
  },

  updateRequest({ commit, dispatch }, payload) {
    const request = {
      id: Number(payload.request_id),
      data: { ...payload.request },
    };

    if (request.data.state_id) {
      request.data.state = {
        id: request.data.state_id,
      };
    }

    if (useAuthStore().user?.is_operator) {
      commit('operator/requests/REQUEST_UPDATE', request, { root: true });
      dispatch('operator/requests/getPendingRequests', {}, { root: true });
    } else {
      commit('client/requests/REQUEST_UPDATE', request, { root: true });
    }
  },

  removeRequest({ commit, dispatch }, payload) {
    if (useAuthStore().user?.is_operator) {
      dispatch('operator/requests/removeRequest', payload.request_id, { root: true });
      dispatch('operator/requests/getPendingRequests', {}, { root: true });
    } else {
      commit('client/requests/REQUEST_REMOVE', payload.request_id, { root: true });
    }
  },

  forwardRequest({ commit }, payload) {
    const data = {
      id: payload.request_id,
      from_operator: payload.from_operator,
      service_id: payload.service_id,
      equip_reports: payload.equip_reports,
    };

    if (useAuthStore().user?.is_operator) {
      commit('operator/requests/TO_FORWARD_SET', data, { root: true });
    }
  },

  forwardedRequest({ commit, dispatch }, payload) {
    const data = {
      id: payload.request_id,
      isSuccess: payload.command !== 'forward-request-declined',
      from_operator: payload.from_operator,
    };

    if (useAuthStore().user?.is_operator) {
      commit('operator/requests/FORWARDED_SET', data, { root: true });
      dispatch('operator/requests/getPendingRequests', {}, { root: true });
    }
  },

  inviteAssistRequest({ commit }, payload) {
    const data = {
      id: payload.request_id,
      from_operator: payload.from_operator,
    };

    if (useAuthStore().user?.is_operator) {
      commit('operator/requests/INVITE_ASSISTANT_SET', data, { root: true });
    }
  },

  declineAssistRequest({ commit, dispatch }, payload) {
    const data = {
      id: payload.request_id,
      isSuccess: payload.command !== 'decline_assist_request',
      from_operator: payload.from_operator,
    };

    if (useAuthStore().user?.is_operator) {
      commit('operator/requests/INVITE_ASSISTANT_RESPONSE_SET', data, { root: true });
      dispatch('operator/requests/getPendingRequests', {}, { root: true });
    }
  },

  requestQueue({ commit }, payload) {
    if (!useAuthStore().user?.is_operator) {
      commit('client/requests/REQUEST_UPDATE', {
        id: payload.request_id,
        data: { requests_in_queue: payload.requests_in_queue },
      }, { root: true });
    }
  },
};
