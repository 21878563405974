const FILTER_BASE = {
  search: '',
  verbs: [],
  types: [],
  actions: [],
  actor: null,
  date: [],
};

export const state = () => ({
  total: 0,
  perPage: 25,
  activities: [],
  verbs: [
    {
      id: 'created',
      title: 'Přidání',
    },
    {
      id: 'updated',
      title: 'Úprava',
    },
    {
      id: 'deleted',
      title: 'Odstranění',
    },
  ],
  types: [
    {
      id: 'shift',
      title: 'Služba',
    },
    {
      id: 'user',
      title: 'Uživatel',
    },
  ],
  page: {
    current: 1,
  },
  filter: FILTER_BASE,
});

export const mutations = {
  ACTIVITIES_SET(state, payload) {
    state.activities = payload;
  },
  ACTIVITIES_TOTAL_SET(state, payload) {
    state.total = payload;
  },
  PAGE_UPDATE(state, payload) {
    state.page = { ...state.page, ...payload };
  },
  FILTER_UPDATE(state, payload) {
    state.filter = { ...state.filter, ...payload };
  },
  FILTER_RESET(state) {
    state.filter = FILTER_BASE;
  },
};

export const getters = {
};

export const actions = {
  async getActivities({ commit, state }) {
    const response = await useApi.get('/admin/activity', {
      params: {
        limit: state.perPage,
        page: state.page.current,
        verb: state.filter.verbs.length ? [...state.filter.verbs].join(',') : undefined,
        types: state.filter.types.length ? [...state.filter.types].join(',') : undefined,
        from_date: state.filter.date && state.filter.date.length ? dayjs(state.filter.date[0]).format('YYYYMMDD') : undefined,
        to_date: state.filter.date && state.filter.date.length ? dayjs(state.filter.date[1]).format('YYYYMMDD') : undefined,
        actor: state.filter.actor ? state.filter.actor : undefined,
        q: state.filter.search.length > 2 ? state.filter.search : undefined,
      },
    });

    commit('ACTIVITIES_SET', response.results);
    commit('ACTIVITIES_TOTAL_SET', response.count);
  },

  async changePage({ commit, dispatch }, to) {
    commit('PAGE_UPDATE', { current: to });
    await dispatch('getActivities');
  },
};
