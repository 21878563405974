export const isFormValid = async(form) => {
  const isValid = await form.validate().catch(() => {
    const firstErrorInput = form.$el.querySelector('.is-error input');
    if (firstErrorInput) {
      firstErrorInput.focus();
    }
    return false;
  });

  return isValid;
};

export const throwInternalServerError = (error) => {
  ElMessage.error({
    message: useNuxtApp().$i18n.t('internalServerError'),
    duration: 4000,
    showClose: true,
  });
  console.error(error);
  if (useNuxtApp()?.$sentry) {
    useNuxtApp()?.$sentry.captureException(error);
  }
};
