<template>
  <component
    :is="props.icon"
    v-if="props.icon"
  />
</template>

<script setup>
const props = defineProps({
  filled: {
    type: Boolean,
    required: false,
    default: false,
  },
  fontControlled: {
    type: Boolean,
    required: false,
    default: true,
  },
  icon: {
    type: Object,
    required: true,
  },
  name: {
    type: String,
    default: '',
    required: false,
  },
});
</script>

<style>
.icon svg {
  width: 100%;
  height: auto;
}

.icon.icon--fill > :not(.custom-color),
.icon.icon--fill > :not(.custom-color) * {
  fill: currentColor !important;
}
</style>
