export const useLogout = async() => {
  const loading = ElLoading.service({
    lock: true,
  });

  if (useRoute().name?.includes('requests-id-chat') && useRoute().params.id && useAuthStore().user?.is_temp) {
    await useNuxtApp().$store.dispatch('client/requests/updateRequest', {
      id: useRoute().params.id,
      data: {
        state_id: 4,
      },
    }).catch((error) => console.error(error));
  }

  await useAuthStore().logout();

  await sleep(100);

  await useNuxtApp().$socket.sendObj({
    command: 'logout_everywhere',
  });

  loading.close();

  await navigateTo(useLocalePath()('/login'));
  window.location.reload();
};
