<template>
  <Html>
    <Body id="app">
      <div class="tw-min-h-dvh tw-flex tw-flex-col">
        <AppHeader />
        <main class="tw-pt-6 tw-pb-24 tw-container tw-flex-1 tw-flex tw-flex-col tw-justify-center tw-items-center">
          <div class="tw-text-center">
            <h2 class="tw-text-primary-500 tw-mb-3">
              {{ title }}
            </h2>

            <div
              class="tw-mb-8"
              v-html="props.error.statusCode === 404 ? 'Zkontrolujte zdali je zadaná adresa správně' : props.error.message"
            />
          </div>

          <pre
            v-if="hasStack"
            class="stack-wrapper"
            v-html="props.error.stack"
          />

          <a
            v-else
            href="/"
          >
            <el-button
              type="primary"
            >
              Přejít na hlavní stranu
            </el-button>
          </a>
        </main>
      </div>
    </Body>
  </Html>
</template>

<script setup>
const props = defineProps({
  error: {
    type: Object,
    required: true,
  },
});

// Computed
const hasStack = computed(() => props.error.stack && import.meta.dev);
const title = computed(() => props.error.statusCode === 404 ? 'Stránka nebyla nalezena' : 'Došlo k neočekávané chybě');

useSeoMeta({
  title: `${title.value} | ${useRuntimeConfig()?.public?.title || ''}`,
});
</script>

<style scoped>
.stack-wrapper {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
  padding: 1rem;
  width: 100%;
  overflow-y: auto;
  height: auto;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #444D56;
}
</style>
