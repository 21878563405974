export const state = () => ({
  total: 0,
  perPage: 25,
  shifts: [],
});

export const mutations = {
  SHIFTS_SET(state, payload) {
    state.shifts = payload;
  },
  SHIFTS_TOTAL_SET(state, payload) {
    state.total = payload;
  },
  SHIFT_ADD(state, payload) {
    state.shifts.push(payload);
  },
  SHIFT_UPDATE(state, { id, data }) {
    state.shifts = state.shifts.map((shift) => {
      if (shift.id === id) {
        shift = { ...shift, ...data };
      }

      return shift;
    });
  },
  SHIFT_REMOVE(state, id) {
    state.shifts = state.shifts.filter((shift) => shift.id !== id);
  },
  SHIFTS_REMOVE_BY_HASH(state, hash) {
    state.shifts = state.shifts.filter((shift) => shift.multiple !== hash);
  },
};

export const getters = {
};

export const actions = {
  async getShifts({ commit, state }, { viewName, fromDate, toDate, page, limit, q, serviceId, lang }) {
    let params = {};

    switch (viewName) {
      case 'list':
        params = {
          limit,
          page,
          from_date: fromDate || undefined,
          to_date: toDate || undefined,
          ordering: 'start_date',
          q: q.length > 2 ? q : undefined,
          lang: lang || undefined,
        };
        break;

      case 'calendar':
        params = {
          limit: 999,
          from_date: fromDate,
          to_date: toDate,
          service_id: serviceId,
          lang: lang || undefined,
        };
        break;

      default:
        break;
    }

    const { results, count } = await useApi.get('/admin/shifts', {
      params,
    });

    commit('SHIFTS_SET', results);
    commit('SHIFTS_TOTAL_SET', count);
  },

  async addShift({ commit }, payload) {
    const shift = await useApi.post('/admin/shifts', {
      body: payload,
    });

    commit('SHIFT_ADD', shift);
  },

  async updateShift({ commit }, { id, data }) {
    await useApi.patch(`/admin/shifts/${id}`, {
      body: data,
    });

    commit('SHIFT_UPDATE', { id, data });
  },

  async getShift({ commit }, id) {
    const shift = await useApi.get(`/admin/shifts/${id}`);

    commit('SHIFT_SET', shift);
  },

  async removeShift({ commit }, id) {
    await useApi.delete(`/admin/shifts/${id}`);

    commit('SHIFT_REMOVE', id);
  },

  async removeShiftsByHash({ commit }, hash) {
    await useApi.delete('/admin/shifts/delete-multiple', {
      params: {
        hash,
      },
    });

    commit('SHIFTS_REMOVE_BY_HASH', hash);
  },
};
